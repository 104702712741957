import React, { useState } from 'react';
import axios from 'axios';
import CookieBanner from '../CookieBanner';
//import AiChat from './aiChat'

const Footer = () => {

  const [emailInput, setEmailInput] = useState('')
  const initialMessage = "Monthly digest of what's new and exciting from us."
  const [subscriptionUserMessage, setSubscriptionUserMessage] = useState('')
  const [formStatus, setFormStatus] = useState(null)

  const validateEmail = (email) => {
    // Simple regex for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitEmailList = async(e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('email', emailInput);

      if (emailInput.trim() === "" || validateEmail(emailInput) === false) {
        alert("Please enter an email")
        return;
      } 
        await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/users/email/subscribe`, formData);

        setSubscriptionUserMessage("You have been added to the mail list!")
        setFormStatus('success');
        window.location.reload();

      } catch(ex) {
        setSubscriptionUserMessage("Unable to subscribe to the email list")
        setFormStatus('error');
      }
  }

  return (
    <>
    <CookieBanner />
  <footer className="p-5" style={{backgroundColor: "aliceblue"}}>
  <div className="row">
    <div className="col-6 col-md-2 mb-3">
      <h5>Quick Links</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2">
          <a href="/" className="nav-link p-0 text-muted">Homepage</a>
        </li>
        <li className="nav-item mb-2">
          <a href="/Items" className="nav-link p-0 text-muted">All Resources</a>
        </li>
        <li className="nav-item mb-2">
          <a href="/Topics" className="nav-link p-0 text-muted">All Categories</a>
        </li>
        <li className="nav-item mb-2">
          <a href="/About" className="nav-link p-0 text-muted">About Us</a>
        </li>

      </ul>
    </div>

    <div className="col-6 col-md-2 mb-3">
      <h5>Additional Info</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2">
          <a href="/Privacy" className="nav-link p-0 text-muted">Privacy Policy</a>
        </li>
        <li className="nav-item mb-2">
          <a href="/Terms" className="nav-link p-0 text-muted">Terms and Conditions</a>
        </li>
        <li className="nav-item mb-2">
          <a href="/Contact" className="nav-link p-0 text-muted">Contact Us</a>
        </li>
      </ul>
    </div>


    <div className="col-md-6 offset-md-2 mb-3">
    
    <form id="subscription-form" onSubmit={submitEmailList}>
      
      <h5>Subscribe to our newsletter</h5>
      <p>{initialMessage}</p>
      <span style={{
          color: formStatus === 'success' ? 'green' : formStatus === 'error' ? 'red' : 'initial',
        }}>{subscriptionUserMessage}</span>
      <div className="d-flex flex-column flex-m-row w-100 gap-2">       
        <input type="email" name="email" id="id_email" placeholder="Your Email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} required/>
        <button id="subscribe-button" className="btn btn-primary" type="submit">Subscribe</button>
      </div>
    </form>
    </div>
  </div>

  <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
  
    <ul style={{margin:"auto", fontSize:"1.2rem"}} className="list-unstyled d-flex">
      <li className="ms-2">
      <a href="https://www.instagram.com/senresource" className="fa fa-instagram"></a>
      </li>

    </ul>
  </div>
  <p style={{textAlign:"center"}}>&copy; {new Date().getFullYear()} SENResource.com by MycoBloom. All Rights Reserved.</p>
</footer>
</>

)
};

export default Footer;
//<AiChat />
