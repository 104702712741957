import React, { useState, useEffect } from 'react';
import '../../styling/popUpForm.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../static/SEN-Logo-Clear.png';
import LoadingWheel from '../loading';
import { Form, Button } from 'react-bootstrap';

const CreateItem = ({ resource, onSubmit, title }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [topicOption, setTopicOption] = useState('');
  const [targetAgeOption, setTargetAgeOption] = useState('');
  const [itemImage, setItemImage] = useState(null);
  const [topicsAvailable, setTopicsAvailable] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`)
      .then(response => {
        setTopicsAvailable(response.data);
      })
      .catch(error => {
        console.error('Error fetching topics:', error);
      });
  }, []);

  const options = topicsAvailable.map(topic => (
    <option key={topic._id} value={topic._id}>{topic.name}</option>
  ));

  const validateForm = () => {
    if (!topicOption.trim()) {
      return 'Please select a topic';
    }
    if (!targetAgeOption.trim()) {
      return 'Please select a target age';
    }
    if (!itemName.trim()) {
      return 'Resource name cannot be empty';
    }
    if (!itemDescription.trim()) {
      return 'Resource description cannot be empty';
    }
    if (itemImage && !itemImage.name.toLowerCase().endsWith('.pdf')) {
      return 'Please upload PDF files only';
    }
    if (itemImage && itemImage.size > 10 * 1024 * 1024) { // Check if file is larger than 10MB
      return 'File size must be 10MB or below';
    }
    return ''; // No errors
  };

  const handleButtonClick = async () => {
    setErrorMessage('');
    
    // Validate the form inputs
    const error = validateForm();
    if (error) {
      setErrorMessage(error);
      return;
    }

    setIsSubmitting(true);
    
    try {
      const accessToken = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append('itemName', itemName);
      formData.append('itemDescription', itemDescription);
      formData.append('topicId', topicOption);
      formData.append('itemImage', itemImage);
      formData.append('targetAge', targetAgeOption);

      const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/items`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      
      window.location.href = `https://senresource.com/resource/${response.data.newItem.slug}`;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Error submitting form');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!isSubmitting ? (
        <Form style={{ width: '80%', margin: '5% auto' }}>
          <img style={{ width: '120px', margin: 'auto' }} src={logo} alt='Logo' />
          <h2 style={{ textAlign: 'center' }}>{title} Your Resource</h2>
          
          <div className="form-group">
            <label htmlFor="itemName">Resource Name:</label>
            <input
              name="itemName"
              type="text"
              id="itemName"
              value={itemName}
              maxLength="50"
              onChange={(e) => setItemName(e.target.value)}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="itemDescription">Resource Description:</label>
            <textarea
              name="itemDescription"
              id="itemDescription"
              value={itemDescription}
              style={{padding:"5px", width:"100%", borderRadius:"8px"}}
              onChange={(e) => setItemDescription(e.target.value)}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="topicId">Select topic:</label>
            <select
              id="topicId"
              name="topicId"
              style={{width: "100%"}}
              onChange={(e) => setTopicOption(e.target.value)}
              required
            >
              <option value="">Select</option>
              {options}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="targetAgeId">Select Target Age <span style={{fontSize:"0.8rem"}}><br/>This is meant to be a rough guide</span>:</label>
            <select
              id="targetAgeId"
              name="targetAgeId"
              style={{width: "100%"}}
              onChange={(e) => setTargetAgeOption(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="Early years">Early years</option>
              <option value="Primary">Primary</option>
              <option value="Teenager">Teenager</option>
              <option value="Adult">Adult</option>
            </select>
          </div>
          
          <div className="form-group">
            <label htmlFor="image1">File (PDF Only):</label>
            <input
              type="file"
              id="image1"
              name="image1"
              accept=".pdf"
              onChange={(e) => setItemImage(e.target.files[0])}
              required
            />
          </div>

          <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>
          <Button type="button" className='pull-right' onClick={handleButtonClick}>Submit</Button>
          <p style={{fontSize:"0.8rem", textAlign:"center"}}>By submitting this form you agree to our Privacy Policy and Terms and Conditions</p>
        </Form>
      ) : (
        <div style={{ padding: '10px' }}>
          <h1>Uploading your resource</h1>
          <h2>Do not refresh this page</h2>
          <p>Please be patient, this may take a while</p>
          <LoadingWheel />
        </div>
      )}
    </>
  );
};

export default CreateItem;
