import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../../styling/ViewTopic.css"
import DisplayAllResources from '../../components/map_displays/displayAllResources';
import LoadingWheel from '../../components/loading';
import HeroImage from '../../components/hero';
import { PageLayout } from "../../components/page-layout";

function ViewTopic() {
  const slug = window.location.pathname.split('/').reverse()[0];
  const [loading, setLoading] = useState(false);
  const [topicName, setTopicName] = useState('');
  const [topicDesc, setTopicDesc] = useState('');
  const [topicId, setTopicId] = useState('');
  const [topicImage, setTopicImage] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`+slug)
      .then(response => {
        setTopicName(response.data.name);
        setTopicDesc(response.data.description);
        console.log(response.data._id)
        setTopicId(response.data._id)
        const item = response.data;
        setTopicImage(item.images[0].file);        
      })
      .catch(error => {
        setTopicName("");
        setTopicDesc("");
        setTopicImage("");    
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);


  if (loading) {
    return <div>    <LoadingWheel /></div>;
  }
  return (
    <PageLayout>
    <div className="container-flex">
      <HeroImage imagepath={topicImage} header={topicName} subheader={topicDesc} pad='6%'></HeroImage>
      <br />
      <DisplayAllResources urlPassed={`/v1/items/topic/`+topicId} dataPassed={true}/>


    </div>
    </PageLayout>
  );
}

export default ViewTopic;