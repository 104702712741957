import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import axios from 'axios';
import LoadingWheel from '../loading';
import "../../styling/Resources.css";
import PdfThumbnail from '../pdf/PdfThumbnail';
export default function DisplayAllResources({ urlPassed, dataPassed }) {
  const [itemsData, setItemsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [itemsDisplay, setItemsDisplay] = useState([]);

  useEffect(() => {
      getItems();
  }, [currentPage, itemsPerPage]);

  const getItems = async () => {
    setLoading(true)
    try {
      var response;
      if (dataPassed) {
        response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}${urlPassed}?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`)
      } else {
        response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/items?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`)
      }
      const items = response.data;
      setItemsData(items);
      setItemsDisplay(items.data);
    } catch (error) {
      console.error('Error fetching items:', error);
      setError(true)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const goToFirstPage = () => setCurrentPage(1);
  const goToPrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const goToNextPage = () => currentPage < Math.ceil(itemsData.totalItems / itemsPerPage) && setCurrentPage(currentPage + 1);
  const goToLastPage = () => setCurrentPage(Math.ceil(itemsData.totalItems / itemsPerPage));

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to first page on items per page change
  };

  if (loading) {
    return <LoadingWheel />;
  } else if (!itemsData || itemsData.totalItems === 0 || error) {
    return (
      <>
        <Link className="addResourceBtn" to="/Resources/Create" style={{marginLeft:"20px"}}>+Resource</Link>
        <h1>Sorry, we cannot find what you are looking for!</h1>
        <p>Please try again later</p>
      </>
    );
  }

  return (
    <>
      <Link className="addResourceBtn" to="/Resources/Create" style={{marginLeft:"20px"}}>+Resource</Link>
      
      <div className="itemsList">
        {itemsDisplay.map((item) => (
          <div className="itemsListItem" key={item._id}>
            <Link className="itemsListLink" to={`/resource/${item.slug}`}>
              <div
                className="itemsListImageContainer"
                style={{ borderRadius: "5px", padding: '10px', boxSizing: 'border-box' }}
              >
                {item.images[0] && item.images[0].file.endsWith('.pdf') ? (
                  <PdfThumbnail pdfUrl={item.images[0].file} />
                ) : (
                  <p>File is not a PDF</p>
                )}
              </div>
              <div className="itemsListContainer">
              {item.targetAge && (<span style={{ fontSize: "0.8rem" }}>For ages: {item.targetAge}</span>)}
                <p style={{ fontSize: "0.8rem", fontWeight: "bold" }}>Created By: {item.user}</p>
                <h2>{item.name}</h2>
                <span style={{ color: "rgb(49, 151, 240)", fontSize: "0.8rem", float: "right" }}>Read More</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Pagination style={{ margin: "4% auto" }}>
        <Pagination.First onClick={goToFirstPage} disabled={currentPage === 1} />
        <Pagination.Prev onClick={goToPrevPage} disabled={currentPage === 1} />
        <Pagination.Item active>
          Page {currentPage} of {Math.ceil(itemsData.totalItems / itemsPerPage)}
        </Pagination.Item>
        <Pagination.Next onClick={goToNextPage} disabled={currentPage === Math.ceil(itemsData.totalItems / itemsPerPage)} />
        <Pagination.Last onClick={goToLastPage} disabled={currentPage === Math.ceil(itemsData.totalItems / itemsPerPage)} />
      </Pagination>
      
      <div className='p-4'>
      <label htmlFor="itemsPerPage" style={{ width: 'fit-content', fontSize: "0.8rem" }}>
        Items Per Page:
      </label>
      <select
        id="itemsPerPage"
        value={itemsPerPage}
        onChange={handleItemsPerPageChange}
        style={{
          padding: '5px',
          width: 'fit-content',
          borderRadius: '5px',
          border: '1px solid #ccc',
          fontSize: '16px',
          backgroundColor: '#fff',
          cursor: 'pointer',
        }}
      >
        <option value={10}>10</option>
        <option value={20} selected>20</option>
        <option value={30}>30</option>
      </select>
      </div>
    </>
  );
}



 
 